import React from 'react';
import axios from 'axios';
import $, { contains } from 'jquery';
import { withRouter, Link } from 'react-router-dom';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import MetaCreation from "../../utils/MetaCreation";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import Constant from "../../utils/Constant";
import LocalStorageService from "../../utils/LocalStorageService";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Slider from "../section/Slider";
import Channel from "../section/Channel";
import Episode from "../section/Episode";
import MobileEpisode from "../section/MobileEpisode";
import Program from "../section/Program";
import MobileProgram from "../section/MobileProgram";
import Fav_Channel from "../section/Fav_Channel";
import LiveChannels from "../section/LiveChannels";
import Event_Banner from "../section/Event_Banner";
import RecommendChannel from "../section/RecommendChannel";
import NoInternetConnect from "../section/NoInternetConnect";
import RecommendProgram from "../section/RecommendProgram";
import Meta from "../section/Meta";
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactGA from "react-ga";
import SliderNew from '../section/SliderNew';
import TvGuideNow from '../section/TvGuideNow';
import MobileTvGuideNow from '../section/MobileTvGuideNow';
import TvGuideUpnext from '../section/TvGuideUpnext';
import TopTrending from '../section/TopTrending';
import MobileTopTrending from '../section/MobileTopTrending';
import Genre from '../section/Genre';
import UnifiedBanner from '../section/UnifiedBanner';
import {isMobile} from "react-device-detect";
import HomeShimmer from '../section/HomeShimmer';
import SliderShimmer from '../section/SliderShimmer';
import SliderNewMobile from '../section/SliderNewMobile';
import TvGuideNowShimmer from '../section/TvGuideNowShimmer';
import MoviesCard from '../section/MoviesCard';
import ImageWebp from 'react-image-webp'
import Image from 'react-shimmer-effect'
import MovieProgram from '../section/MovieProgram';
import Loader from '../section/loader';
import ReactPlayer from 'react-player';
import StarRatingComponent from 'react-star-rating-component';
import HeaderNew from '../common/HeaderNew';
import { program } from 'babel-types';
import { Sticky } from 'react-uikit3';
import WC_Movie from '../section/WC_Movie';
import GeneralSeriesWatch from '../section/GeneralSeriesWatch';


var adTimerInterval;
var initializeAdTimer;
class Home extends React.Component {

    
    constructor() {
        super();
        
        //banner
        this.videoRef = React.createRef();
        this.callBannerApi = this.callBannerApi.bind(this);
        this.handleBannerList = this.handleBannerList.bind(this);
        //home body
        this.callHomeApi = this.callHomeApi.bind(this);
        // this.callLiveGuideApi = this.callLiveGuideApi.bind(this);
        this.handleHomeList = this.handleHomeList.bind(this);
        this.handleTopicBaseData = this.handleTopicBaseData.bind(this);
        ReactGA.initialize('UA-118978593-1');
        this.state = {
            mastHead: {
                url: '',
                image: '',
                isEnable: true
            },
            banners: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            homes: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            topicBase: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            guideUpNext: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            pageNumber: 1,
            hasMore: true,
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: '',
            // temporary popup states
            userIP: null,
          cardData: null,
          userCountry: '',
          fixedCountry: "PK",
          showPopup: false,
            cardNumber: '',
          validCard: true,
          firstName: '',
            lastName: '',
            creditCardNumber: '',
            validThru: '',
            cvv: '',
            payment_type: '',
            isHomeLoaded :false,
            isBannerMuted: true,
            totopvisible : false
        }
    }
    componentWillMount(){
    }
    componentDidMount() {

        // this.fetchIP();

        
        let classRef = this;

        //totop button to visible after scroll 

        window.addEventListener('scroll', function(){
            let scrollPos = this.scrollY;
            // console.log("on mount scroll position: ", this.scrollY)
            if(scrollPos > 20){
                classRef.setState({totopvisible : true});
            }
            else{
                classRef.setState({totopvisible : false});
            }
        })


        MetaCreation.createMeta('static', 'home', (e) => this.callMetaData(e));
        LocalStorageService._serviceToken(this.initializeToken);

        
        classRef.checkLocalStorage();
        // console.log(cookieData);
        window.tagManagerEvent("Home Screen", "Home", "Home", { placement_channel: "", placement_program: "", placement_genre: "", user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') : "Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) : "", user_interest: '', user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime, custom_ga_client_id: window.ga_client_id !== '' ? window.ga_client_id : '', email: localStorage.getItem('email') ? localStorage.getItem('email') : '', name: localStorage.getItem('name') ? localStorage.getItem('name') : '', number: localStorage.getItem('mobile') ? localStorage.getItem('mobile') : '', search_keyword: '', user_custom_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '', user_device_id: '' });
        
        setTimeout(function(){
            if(classRef.state.userCountry !== '' && classRef.state.userCountry === 'AE' && classRef.state.cardData === null){
                $("#modalAppLoad").addClass('uk-flex uk-open');
                // console.log(classRef.state.cardData)
            }
        }, 5000);
        // console.log("here on mount" + this.state.userCountry + " " + this.state.userIP );


        
       
  
    }

    checkLocalStorage = () => {
        let classRef = this;
        const cookieData = JSON.parse(localStorage.getItem('creditCardData')) || null;
        classRef.setState({ cardData: cookieData });
    }

    componentWillUnmount() {
        if(this.state.mastHead.isEnable){
            clearInterval(adTimerInterval);
            clearInterval(initializeAdTimer);
        }
    }

    initializeAd = () => {
        var classRef = this;
        initializeAdTimer = setTimeout(function () {


            // var src = {
            //     hls: this.state.channelUrl.url
            // };
            // var backupSrc = {
            //     hls: this.state.backup_live_stream_url
            // };
            // Then we set our player settings



            var adSettings = {
                licenseKey: 'Kl8laXE4a2VlazI3OT9yb201ZGFzaXMzMGRiMEElXyo=',
                width: 640,
                height: 360,
                skin: 's3',
                autoplay: true,
                ads: true,
                adTagReloadOnEnded: false,
                // by default we use Google IMA but we can also use rmp-vast for outstream ads
                // adParser: 'rmp-vast',
                adOutStream: true,
                adTagUrl: classRef.state.mastHead.url,
                // we use client-side waterfalling in this case (optional)
                // adTagWaterfall: [
                //     'https://pubads.g.doubleclick.net/gampad/live/ads?iu=/205019744/MJUNOON.TV_MastheadAd&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='
                // ]
            };

            // Reference to player container
            var elementID = 'adPlayer';
            // Object creation
            var rmp = window.initializeRMP(elementID);
            var container = document.getElementById(elementID);

            var _trace = function (input) {
                if (window.console.trace && input) {
                    window.console.trace(input);
                }
            };

            var _removeElement = function (element) {
                var parent = element.parentNode;
                if (parent) {
                    try {
                        parent.removeChild(element);
                    } catch (e) {
                        _trace(e);
                    }
                }
            };

            // when destroy method finishes we clear listeners and remove player from DOM
            var _onDestroyCompleted = function () {
                container.removeEventListener('destroycompleted', _onDestroyCompleted);
                _removeElement(container);
            };

            // player needs be removed from page
            // first we need to destroy it
            var _removePlayer = function () {

                $('.ad_cont').removeClass("ad_act_ii");
                $('.sidebar').removeClass("ad_act");

                container.addEventListener('destroycompleted', _onDestroyCompleted);
                rmp.destroy();
            };

            // function to fade out player
            var _endPlayer = function () {
                container.removeEventListener('autoplayfailure', _endPlayer);
                container.removeEventListener('adcontentresumerequested', _endPlayer);
                container.removeEventListener('addestroyed', _endPlayer);
                // nicely fade out player and remove it from DOM
                container.style.opacity = 0;
                container.style.visibility = 'hidden';

                setTimeout(function () {
                    // if(classRef.state.mastHead.isEnable){
                    _removePlayer();
                    // }

                }, 400);
            };

            container.addEventListener('ready', function () {
                // if Google IMA has been blocked by an ad-blocker or failed to load
                // we need to remove the player from DOM
                // $('.ad_space ').slideToggle('slow').addClass( "active" );
                // $('.sidebar').css("top","420px");
                // $('.sidebar').addClass('ad_act');
                // $('.ad_cont').addClass('ad_act_ii');
                // document.documentElement.scrollTop = 0;
                $('html, body').animate({
                    scrollTop: $("body").offset().top
                }, 1000)

                if (rmp.getAdParserBlocked()) {
                    _removePlayer();
                    return;
                }
            });

            // when ad ends - adcontentresumerequested event for Google IMA or addestroyed event for rmp-vast
            // we fade out player and remove it from DOM
            // in case of autoplayfailure event we also need to remove it - note that autoplayfailure should
            // be infrequent if you are using muted autoplay as recommended
            // whichever comes first
            container.addEventListener('autoplayfailure', _endPlayer);
            container.addEventListener('adcontentresumerequested', _endPlayer);
            container.addEventListener('addestroyed', _endPlayer);

            // Player initialisation - only after API events are registered
            rmp.init(adSettings);
            window.ADRMP = rmp;
        }, 1000)


    }
    callMetaData = (metaResponse) => {

        this.setState({
            metaKeywords: metaResponse.keywords,
            metaTitle: metaResponse.title,
            metaDescription: metaResponse.description,
            metaImage: metaResponse.thumbnail,
            schema: metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    initializeToken = () => {
        this.callBannerApi();
        this.callHomeApi();
        // this.callLiveGuideApi();
    }


    componentDidUpdate() {

        // this.scrollCheck();
        // this.callBannerApi();
        // if(!this.state.banners.isLoaded){
        //
        // }
    }
    // banner functions
    mutePlayer = () => {        
        this.setState({
            isBannerMuted :true
        },function(){
            $('#bannermuter').show();
            $('#bannerunmuter').hide();
        });        
    }
    
    unmutePlayer = () => {
        
        this.setState({
            isBannerMuted :false
        },function(){
            $('#bannermuter').hide();
            $('#bannerunmuter').show();
        }); 
    }
    // banner functions

    //Calling Banner Api
    // callBannerApi = () => {
    //     let classRef = this;        
    //     {/**
    //         changed api here from "slider-v3" to "slider-new" below in axios
    //     */}
    //     axios.get(Constant.url + "slider-v3?user_id="+LocalStorageService.encryptAes(localStorage.getItem('user_id')), 
    //     { 
    //         headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") } 
    //     }
    //     )
    //     .then(function (result) {
    //             let response = {};
    //             response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
    //             if (response.data.code === 200) {

    //                 classRef.setState({
    //                     mastHead: {
    //                         url: response.data.mast_head_url,
    //                         image: response.data.mast_head_image
    //                     },
    //                     banners: {
    //                         isLoaded: true,
    //                         list: response.data.data.slider
    //                     }
    //                 });
    //                 if (response.data.is_mast == '1') {
    //                     adTimerInterval = setTimeout(function () {
    //                         $(".ad_cont").addClass('ad_act_ii');
    //                         if(classRef.state.mastHead.isEnable){
    //                             classRef.initializeAd();
    //                         }
    //                     }, 3000);
    //                 }
    //             }
    //         }).then(function (error) {

    //     });
    // };
    //Calling Banner Api
    callBannerApi = () => {
        let classRef = this;
        axios.get(Constant.url+"sliders-v2",{headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}})
            .then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                if(response.data.code === 200){
                    // console.log("sliders: ", response.data)
                    classRef.setState({
                        mastHead: {
                            url:   response.data.mast_head_url,
                            image: response.data.mast_head_image
                        },
                        banners:{
                            isLoaded : true,
                            list: response.data.data.slider
                        }
                    });
                    if(response.data.is_mast == '1') {
                        adTimerInterval = setTimeout(function () {
                            $(".ad_cont").addClass('ad_act_ii');
                            classRef.initializeAd();
                        }, 3000);
                    }
                }
            }).then(function (error) {
            // console.log(error);
        });
    };




    handleBannerList2 = () => {

        let b2 = [
            

        
            
            // {
            //     "name": "Gang of Gossip Girls",
            //     "thumbnail": null,
            //     "thumbnail_jpg": "",
            //     "m_web_banner": "img/05.png",
            //     "m_web_banner_jpg": "https://images.mjunoon.tv:8181/slider/1702285031_GOGG.png",
            //     "is_redirect": true,
            //     "redirect_url": "gang-of-gossip-girls",
            //     "link": "gang-of-gossip-girls",
            //     "type": "program",
            //     "views": "705",
            //     "video": null,
            //     "m_video": null
            // },
            {
                "name": "To Be Honest",
                "thumbnail": null,
                "thumbnail_jpg": "",
                "m_web_banner": "img/s2.png",
                "m_web_banner_jpg": "https://images.mjunoon.tv:8181/slider/1702285031_GOGG.png",
                "is_redirect": true,
                "redirect_url": "gang-of-gossip-girls",
                "link": "gang-of-gossip-girls",
                "type": "program",
                "views": "705",
                "video": null,
                "m_video": null
            },
            {
                "name": "CARTOON NETWORK",
                "thumbnail": null,
                "thumbnail_jpg": "",
                "m_web_banner": "img/s3.png",
                "m_web_banner_jpg": "https://images.mjunoon.tv:8181/slider/1702285031_GOGG.png",
                "is_redirect": true,
                "redirect_url": "gang-of-gossip-girls",
                "link": "gang-of-gossip-girls",
                "type": "program",
                "views": "705",
                "video": null,
                "m_video": null
            },
            {
                "name": "DO TOK BAT",
                "thumbnail": "https://images.mjunoon.tv:8181/slider/1701440748_02AikAurAllLand-1290x600.webp",
                "thumbnail_jpg": "https://images.mjunoon.tv:8181/slider/1701440748_02AikAurAllLand-1290x600.png",
                "m_web_banner": "img/s1.png",
                "m_web_banner_jpg": "https://images.mjunoon.tv:8181/slider/1701440748_02AikAurAllLand-1290x600.png",
                "is_redirect": true,
                "redirect_url": "movie-one-live",
                "link": "movie-one-live",
                "type": "program",
                "views": "83.1K",
                "video": null,
                "m_video": null
            },
            {
                "name": "CHIKKAR",
                "thumbnail": null,
                "thumbnail_jpg": "",
                "m_web_banner": "img/chikkar-new.png",
                "m_web_banner_jpg": "img/test_P.png",
                "is_redirect": true,
                "redirect_url": "urdu-1-live",
                "link": "urdu-1-live",
                "type": "program",
                "views": "813.3K",
                "video": null,
                "m_video": null
            },
            
            // {
            //     "name": "JAHAN TUM WAHAN HUM",
            //     "thumbnail": null,
            //     "thumbnail_jpg": "",
            //     "m_web_banner": "img/06.png",
            //     "m_web_banner_jpg": "https://images.mjunoon.tv:8181/slider/1702285031_GOGG.png",
            //     "is_redirect": true,
            //     "redirect_url": "gang-of-gossip-girls",
            //     "link": "gang-of-gossip-girls",
            //     "type": "program",
            //     "views": "705",
            //     "video": null,
            //     "m_video": null
            // },
        
        
        ]
            

        let banners = "";
        // console.log("banner list: ", this.state.banners.list);
        // console.log('umair',this.state.banners.list)
        banners = this.state.banners.list.map((banner, index) => (
          <li key={index} className={index === 0 ? "first-banner" : ""}>
            <div className="uk-panel">
              {/* if isredirectable is true then route */}

              {banner.type === "live" ? (
                <span className="live_span">Live</span>
              ) : (
                " "
              )}
              {banner.type === "live" ? (
                // <span className="views_txt">
                //   <img src="/images/eye.svg" alt="eye" uk-svg="" />
                //   {banner.views}
                // </span>
                <></>
              ) : (
                ""
              )}
              {banner.name !== null && banner.type !== "link" ? (
                <div className="banner_item">
                  <div className="gridbox">
                    <div className="uk-grid bannergrid " uk-grid=" ">
                      <div className="uk-width-1-1">
                        { banner.type === 'poster' ? '' : <h1 className="banner_name">{banner.name}</h1>}
                      </div>
                      {banner.rating !== 0 ? (
                        <div
                          className="banner_rating uk-width-1-1"
                          style={{ marginBottom: "-20px" }}
                        >
                          <div className="banner_rating_wrap">
                            <StarRatingComponent
                              starCount={5}
                              value={banner.rating}
                              starSpacing={20}
                              className="custom-star-rating"
                            />
                            <div
                              className="imdblogo"
                              style={{ display: "flex" }}
                            >
                              {/* <p >4.0</p> */}

                              <div className="temp">
                                <img className="imdb" src="img/IMDB_Logo.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {
                      
                      banner.description !== null ? (
                        <div className=" banner_text  uk-width-1-1">
                          <div className="banner_release ">
                            <h3>{banner.season} </h3>
                            <h3
                              className="uk-margin-remove-top"
                              style={{ marginLeft: "20px" }}
                            >
                              {banner.release_date}
                            </h3>
                          </div>
                          <h4 style={{ marginTop: "0px" }}>
                            {banner.description}{" "}
                          </h4>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="banner_play  uk-width-1-1">
                        {banner.type === "program" ? (
                          <>
                            {/* <button class="" onClick={() => window.location.href = ("/program/" + banner.redirect_url, "_blank" )}>
                            
                          <img src="/images/play.svg" uk-svg="" />
                          <span>Play Now</span>
                        
                        </button> */}
                            <button
                              class=""
                              onClick={() =>
                                (window.location.href =
                                  "/program/" + banner.redirect_url)
                              }
                            >
                              {/* <img src="/images/play.svg" uk-svg="" />s */}
                              <span>Watch Now</span>
                            </button>
                          </>
                        ) : banner.type === "movie" ? (
                          <>
                            <button
                              class=""
                              onClick={() =>
                                (window.location.href =
                                  "/movies/" + banner.redirect_url)
                              }
                            >
                              {/* <img src="/images/play.svg" uk-svg="" /> */}
                              <span>Watch Now</span>
                            </button>
                          </>

                        ) : banner.type === "poster" ? (
                          <>
                            
                          </>
                        ) : (
                          <>
                            <button
                              class=""
                              onClick={() =>
                                (window.location.href =
                                  "/" + banner.redirect_url)
                              }
                            >
                              {/* <img src="/images/play.svg" uk-svg="" /> */}
                              <span>Watch Now</span>
                            </button>
                          </>
                        )}

                        {/* <img><svg src = "img/sss.svg"/></img> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : banner.type == "link" ? (
                <div className="banner_item"></div>
              ) : (
                ""
              )}
              <div className="imgContainer-banner">
                {banner.video !== null ? (
                  <>
                    <video
                      style={{ width: "100%", height: "100%" }}
                      id={"bannerVideo"}
                      src={banner.video}
                      poster={banner.thumbnail_jpg}
                      loop="true"
                      uk-video="autoplay: true"
                      muted={this.state.isBannerMuted ? true : false}
                    ></video>
                    {/* <video style={{width:'100%', height:'100%'}} id={"bannerVideo"} src={banner.video} poster={banner.thumbnail_jpg} loop={true} autoPlay ></video> */}
                    <div
                      id="bannerunmuter"
                      className="unmuter volume_style"
                      onClick={this.mutePlayer}
                      style={{ display: "none" }}
                    >
                      <img
                        src="/images/volume_up.svg"
                        alt="volume_up"
                        uk-svg=""
                      />
                    </div>
                    <div
                      id="bannermuter"
                      className="muter volume_style"
                      onClick={this.unmutePlayer}
                    >
                      <img
                        src="/images/volume_off.svg"
                        alt="volume_off"
                        uk-svg=""
                      />
                    </div>
                  </>
                ) : banner.type == "link" ? (
                  <a href={banner.link}>
                    {isWebpSupported() ? (
                      <img
                      style={{height:'100%'}}

                    //   loading='lazy'
                        src={banner.m_web_banner}
                        alt={banner.name}
                        //   width={805}
                        //   height={574}
                        color={"#0d0d19"}
                      />
                    ) : (
                      <img
                    //   loading='lazy'
                    style={{height:'100%'}}

                        src={banner.m_web_banner_jpg}
                        alt={banner.name}
                        //   width={805}
                        //   height={574}
                        color={"#0d0d19"}
                      />
                    )}
                    {/* <div className="gradientOverlay"></div> */}
                  </a>
                ) : (
                  <>
                    {isWebpSupported() ? (
                      <img
                    //   loading='lazy'
                        style={{height:'100%'}}
                        src={banner.m_web_banner}
                        alt={banner.name}
                        //   width={805}
                        //   height={574}
                        color={"#0d0d19"}
                      />
                    ) : (
                      <img
                      style={{height:'100%'}}

                    //   loading='lazy'
                        src={banner.m_web_banner_jpg}
                        alt={banner.name}
                        //   width={805}
                        //   height={574}
                        color={"#0d0d19"}
                      />
                    )}
                    {/* <div className="gradientOverlay"></div> */}
                  </>
                )}
              </div>
            </div>
          </li>
        ));
        return banners;
    }




    //Handling Banner web api response with headling html section
    handleBannerList = () => {
        let banners = "";
        console.log("banner list: ", this.state.banners.list);

        banners = this.state.banners.list.map((banner,index) => (
            // <li key={index}>
            <li key={index} className={index === 0 ? "first-banner" : ""}>
                <div className="uk-panel">

                    {/* if isredirectable is true then route */}

                    {banner.is_redirect === true ?  (

                    <Link to = {'/'+banner.redirect_url}>
                    {banner.type === 'live' ? <span className="live_span">Live</span> : ''}
                    {banner.type === 'live' ?<span className="views_txt"><img src="/images/eye.svg" alt="eye" uk-svg="" />{banner.views}</span>:''}
                    {banner.video !== null ? <>
                        {/* <video id={"bannerVideo"} src={banner.video} poster={banner.thumbnail_jpg} loop={true} autoPlay></video> */}
                    </> : <>
                    {isWebpSupported() ? <img src={banner.m_web_banner} alt={banner.name} width={805} height={454} color={'#0d0d19'}  /> : <img src={banner.m_web_banner_jpg} alt={banner.name} width={805} height={454} color={'#0d0d19'}  />} 

                    </> }
                    </Link>) 
                    
                    // else only show image 

                    :(
                    <>
                        <div className='inner-div'>
                            {banner.type === 'live' ? <span className="live_span">Live</span> : ''}
                            {banner.type === 'live' ?<span className="views_txt"><img src="/images/eye.svg" alt="eye" uk-svg="" />{banner.views}</span>:''}
                            {banner.video !== null ? <>
                                <video id={"bannerVideo"} src={banner.video} poster={banner.thumbnail_jpg} loop="true" uk-video="autoplay: true" muted={(this.state.isBannerMuted) ? true : false}>
                                    
                                </video>
                                <div id="bannerunmuter" className="unmuter volume_style" onClick = {this.mutePlayer} style={{"display" : "none"}}><img  src="/images/volume_up.svg" alt="volume_up" uk-svg=""/></div>
                                <div id="bannermuter" className="muter volume_style" onClick = {this.unmutePlayer}><img src="/images/volume_off.svg" alt="volume_off" uk-svg=""/></div>
                            </> : <>
                                {isWebpSupported() ? <img src={banner.m_web_banner} alt={banner.name} width={805} height={454} color={'#0d0d19'}  /> : <img src={banner.m_web_banner_jpg} alt={banner.name} width={805} height={454} color={'#0d0d19'}  />} 
                            

                            </> }
                        </div>
                    </>
                    )
                    }  
                    




                    {/* <Link to = {'/'+banner.redirect_url}>
                    {banner.type === 'live' ? <span className="live_span">Live</span> : ''}
                    {banner.type === 'live' ?<span className="views_txt"><img src="/images/eye.svg" alt="eye" uk-svg="" />{banner.views}</span>:''}
                    {isWebpSupported() ? <img src={banner.m_web_banner} alt={banner.name} width={805} height={454} color={'#0d0d19'}  /> : <img src={banner.m_web_banner_jpg} alt={banner.name} width={805} height={454} color={'#0d0d19'}  />} 

                    </Link> */}


                    

                    {/*<img src={banner.thumbnail} alt={banner.name}/></Link> */}
                </div>
            </li>
        ));
        // $('.uk-slider-items').attr('style', 'transform: translate3d(-513.75px, 0px, 0px);');
        return banners;
    };



    //Calling Home Api
    callHomeApi = () => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        let page = 1;
        let params = JSON.stringify({user_id: user_id, page: page});
        axios({
            method: 'POST',
            url: Constant.url + "home-new-v5",

            data: { dataparams: LocalStorageService.encryptAes(params)},
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            // console.log("call home api - home-new: ", response.data);
            if (response.data.code === 200) {
                // debugger;
                classRef.setState({isHomeLoaded : true});
                classRef.setState({
                    homes: {
                        isLoaded: true,
                        list: response.data.data.body
                    }
                },function(){
                    // setTimeout(function(){
                    //     $('body').removeClass('loadkaro');
                    // },800)
                })

            }
        }).then(function (response) {

        });

    };
    topicBase = () => {
        LocalStorageService._serviceToken(this.callTopicBaseData);
    }

    callTopicBaseData = () => {
        // if(this.state.pageNumber == 1){
        //     this.callHomeApi();
        // }
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        let page = this.state.pageNumber;
        let params = JSON.stringify({user_id: user_id, page: page});
        // console.log("page",page);
        axios({
            method: 'POST',
            url: Constant.url + "topic-base-new-v3",
            data: { dataparams: LocalStorageService.encryptAes(params)},
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {

                window.userTopics = response.data.data.user_topics;
                classRef.setState({
                    topicBase: {
                        isLoaded: true,
                        list: [...classRef.state.topicBase.list, ...response.data.data.body]
                    },
                    pageNumber: classRef.state.pageNumber + 1,
                    hasMore: false//response.data.data.hasMore  //yaseen working 4/July/2023
                })
            }
            DFPManager.setCollapseEmptyDivs(false);
            DFPManager.load();
            window.imgNotFound();
        }).then(function (response) {

        });
    }

    //Handling Home web api response with headling html section
    handleHomeList = () => {
        let homes = "";
        let index = 0;
        let keyval = 100;
        homes = this.state.homes.list.map((home, key) => {
            keyval++;
            index++;
            // console.log("handle homes List: ", home);
            if (home.type === 'google_ads' && localStorage.getItem('package_status') != "true") {
                return window.mobilecheck() === false ?
                    <div key={key} className="ad_container uk-margin-top">
                        <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad" + key} dfpNetworkId="205019744" sizes={[[728, 90]]} adUnit="MJUNOON.TV-728X90" />
                        </div></div> : <div className="ad_container uk-margin-top">
                        <div className="desktop-ads11 mob_ad">
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            <AdSlot slotId={"content-ad-mob" + key} dfpNetworkId="205019744" sizes={[[320, 50]]} adUnit="MJUNOON.TV-320X50" />
                        </div>
                    </div>

                // return (<div className="ad_container uk-margin-top">
                //     <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad"+key} dfpNetworkId="205019744" sizes={[[728, 90]]} adUnit="MJUNOON.TV-728X90" />
                //     </div></div>)
            }
            else if (home.type === 'favourite_channel') {
                return <LiveChannels key={key} ad_class='' name={home.heading} data={home.channel}/>
            } 
            else if (home.type === 'channel') {
                // console.log(home.channel);
                return <Channel key={key} sliderKey={this.state.pageNumber + index + keyval} name={home.heading} data={home.channel} datapro={home.programs} redirection="/watch-live-tv-channels" />
            }
             else if (home.type === 'programs') {
                if(isMobile){
                    // console.log("its mobile bro!!");
                    return <MobileProgram key={key} sliderKey={this.state.pageNumber + index  + keyval} customSlug="" name={home.heading} data={home.programs} />
                }else{
                    
                    return <Program key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.programs} redirection = "/Watch-program" />
                }
            }
             else if (home.type === 'episode') {
                if(isMobile){
                    return <MobileEpisode key={key} sliderKey={this.state.pageNumber + index + keyval} name={home.heading} data={home.episodes} isBranded={home.is_branded} channelSlug={home.channel_slug} />
                }else{
                    return <Episode key={key} sliderKey={this.state.pageNumber + index + keyval} name={home.heading} data={home.episodes} isBranded={home.is_branded} channelSlug={home.channel_slug} />
                }
            } 
            
            else if (home.type === 'event') {
                return <Event_Banner key={key} name={home.heading} data={home.event} />
            }
            else if (home.type === 'movies') {
                // return <MoviesCard key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.episodes} />
                return <MovieProgram key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.episodes} />
            }
            else if (home.type === 'webseries') {
                // return <MoviesCard key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.episodes} />
                // return <MovieProgram key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.episodes} />
               return <GeneralSeriesWatch data= {home.webseries} name = { "Web Series"} />

            }   
            else if (home.type === 'comedy') {
                // return <MoviesCard key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.episodes} />
                // return <MovieProgram key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.episodes} />
               return <GeneralSeriesWatch data= {home.comedy} name = { home.heading} />

            }
            else if (home.type === 'trending_programs') {
                if(isMobile){
                    return <MobileTopTrending key={key} name={home.heading} data={home.programs} />
                }else{
                    return <TopTrending key={key} name={home.heading} data={home.programs} />
                }
            }


            // else if (home.type === 'recommend_channel') {
            //     return <RecommendChannel key={key} name={home.heading} data={home.event} />
            // }
            // else if(home.type === 'recommend_program'){
            //     return <RecommendProgram name={home.heading} data={home.event}/>
            // }
            // index++
        });
        return homes;
    };

    handleTopicBaseData = () => {
        let topicBase = "";
        let index = 0;
        let keyval = 1000;
        let countIndex = this.state.topicBase.list.length;
        let addClassInLast = countIndex - 1;
        // console.log("home topic base:", this.state.topicBase);
        topicBase = this.state.topicBase.list.map((home, key) => {
            index++;
            keyval++;
            if (home.type === 'google_ads' && localStorage.getItem('package_status') != "true") {
                return window.mobilecheck() === false ?
                    <div key={key} className="ad_container uk-margin-top">
                        <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad" + key} dfpNetworkId="205019744" sizes={[[728, 90]]} adUnit="MJUNOON.TV-728X90" />
                        </div></div> : <div className="ad_container uk-margin-top">
                        <div className="desktop-ads11 mob_ad">
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            <AdSlot slotId={"content-ad-mob" + key} dfpNetworkId="205019744" sizes={[[320, 50]]} adUnit="MJUNOON.TV-320X50" />
                        </div>
                    </div>

                // return (<div className="ad_container uk-margin-top">
                //     <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad"+key} dfpNetworkId="205019744" sizes={[[728, 90]]} adUnit="MJUNOON.TV-728X90" />
                //     </div></div>)
            }
            if (home.type === 'channel') {
                return <Channel key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.channel} />
            } 
            else if (home.type === 'episode') {
                // if(isMobile){
                //     return <MobileEpisode key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.episodes} isBranded={home.is_branded} channelSlug={home.channel_slug} />
                // }else{
                //     return <Episode key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.episodes} isBranded={home.is_branded} channelSlug={home.channel_slug} />
                // }
                if(isMobile){
                    return <MobileEpisode key={key} sliderKey={this.state.pageNumber + index + keyval} name={home.heading} data={home.episodes} isBranded={home.is_branded} channelSlug={home.channel_slug} />
                }else{
                    return <Episode key={key} sliderKey={this.state.pageNumber + index + keyval} name={home.heading} data={home.episodes} isBranded={home.is_branded} channelSlug={home.channel_slug} />
                }                
            } 
            else if (home.type === 'programs') {
                if(isMobile){
                    return <MobileProgram key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.programs} />
                }else{
                    return <Program key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.programs} />
                }
            } 
            else if (home.type === 'event') {
                return <Event_Banner key={key} name={home.heading} data={home.event} />
            }
            else if (home.type === 'trending_programs') {
                if(isMobile){
                    return <MobileTopTrending key={key} name={home.heading} data={home.programs} />
                }else{
                    return <TopTrending key={key} name={home.heading} data={home.programs} />
                }
            }
            else if (home.type === 'genre') {
                return <Genre key={key} name={home.heading} data={home.genre} />
            }
            
            else if (home.type === 'movies') {
                return <MoviesCard key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.episodes} />
            }


            // else if(home.type === 'recommend_channel'){
            //     return <RecommendChannel name={home.heading} data={home.event}/>
            // }else if(home.type === 'recommend_program'){
            //     return <RecommendProgram name={home.heading} data={home.event}/>
            // }


        });
        // topicBase += <MobileProgram sliderKey={55} name="Movies" data={this.state.tempProgramms} />
        return topicBase;
    }
    // popup trial
    //   fetchIP = () => {
    //     axios.get("https://ffmpegimages.mjunoon.tv/geolocation/public/api/ipdetails")
    //       .then((response) => {
    //         // const { ip, country } = response.data;
    //         // console.log("ip data: ", response.data);
    //         // this.setState({ userIP: ip, userCountry: country });

    //         if(response.data.data.data.country_code === 'BD'){
    //             window.location.href = "https://bd.mjunoon.tv"
    //         }

    //       })
    //       .catch((error) => {
    //         console.error("Error fetching IP address:", error);
    //       });
    //   };

      handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
      };
      
        handleCardNumberChange = (event) => {
          const input = event.target.value;
          const sanitizedInput = input.replace(/[^0-9]/g, ''); // Remove non-numeric characters
          const isValidLength = sanitizedInput.length <= 16;
            // console.log("input: ", isValidLength);
          if (isValidLength) {
            const maskedInput = sanitizedInput.replace(/(\d{4})/g, '$1 ').trim(); // Add space after every 4 digits
            this.setState({cardNumber: maskedInput})
            this.setState({validCard: true})
            
            
          } else {
            this.setState({validCard: false})
          }
        };

        handleSubmitPopUp = (e) => {
            e.preventDefault();
            const formData = { ...this.state }; // Copy form data
            const tempCookieData = JSON.parse(localStorage.getItem('creditCardData')) || [];

            // Add the new form data to the array
            tempCookieData.push(formData);

            // Update the local storage
            localStorage.setItem('creditCardData', JSON.stringify(tempCookieData));

            // Optionally, you can clear the form after submission
            this.setState({
                payment_type: '',
                firstName: '',
                lastName: '',
                creditCardNumber: '',
                validThru: '',
                cvv: '',
            });

            // console.log('Form submitted and data stored in localStorage:', storedData);
            window.location.reload();
        };
    // popup trial



    render() {
        return (

            
            <div>
                
                {this.state.metaTitle !== '' ? <Meta schema={null} metaKeywords={this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription} metaImage={this.state.metaImage} /> : ''}
                {/*<Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>*/}

                
                
                {/* <HeaderNew /> */}
                                {
                    this.state.isHomeLoaded === true ?  <>
                    <Header/>
                    {(this.state.mastHead.isEnable)?
                        <div className="ad_cont ">
                            <div className="img_before" style={{ backgroundImage: 'url(' + this.state.mastHead.image + ')' }} />
                            <div className="ad_space ">
                                <div className="mastHead" />
                                <div id="adPlayer" />
                            </div>
                        </div>
                        :''}
                    <div className="content_sec home_page">
                        {/* slider from old branch */}
                        <div>
                        {this.state.banners.isLoaded === true ? <Slider data={this.handleBannerList2()}/> : (this.state.banners.isLoaded === false) ? '' : '' }
                        </div>
                        {/* slider from old branch ends */}
                        
                        

                
                        {/* Slider */}
                        
                        {/* {(this.state.banners.isLoaded === true && !isMobile)? 
                            <SliderNew data={this.state.banners.list} /> : 
                        (this.state.banners.isLoaded === true && isMobile)? 
                            <SliderNewMobile data={this.state.banners.list}/>:
                        (!isMobile)?                    
                            <SliderShimmer/>
                        :''
                        } */}

                        {/* Slider ends */}




                        {
                            // (isMobile)?<MobileTvGuideNow />:(this.state.guideUpNext.isLoaded === true)?<TvGuideNow  dataUpnext={this.state.guideUpNext.list}/>:<TvGuideNowShimmer/> 
                        }


                        {/* {(this.state.guideUpNext.isLoaded === true) ?<TvGuideUpnext data={this.state.guideUpNext.list}/>:'' } */}
                        
                                    
                        {/*{window.mobilecheck()  === false ? <div className="ad_container ad_container2">*/}
                        {/*<div className="desktop-ads desktop_ad_large">*/}
                        {/*<AdSlot slotId="test1" dfpNetworkId="205019744" sizes={[[970, 250]]} adUnit="MJUNOON.TV-970x250"  />*/}
                        {/*</div>*/}
                        {/*</div> : <div className="ad_container uk-margin-top">*/}
                        {/*<div className="desktop-ads11 mob_ad">*/}
                        {/*/!*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*!/*/}
                        {/*/!*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*!/*/}
                        {/*<AdSlot slotId="below-slider-ad" dfpNetworkId="205019744" sizes={[ [320, 50]]} adUnit="MJUNOON.TV-320X50" />*/}
                        {/*</div>*/}
                        {/*</div>}*/}
                        {/* {(this.state.guideUpNext.isLoaded === true) ?<TvGuideUpnext isliveContent={this.state.banners.isLoaded} data={this.state.guideUpNext.list}/>:'' } */}


                        {/* {window.mobilecheck()  === false && localStorage.getItem('package_status') != "true" ? <div className="ad_container ad_container2">
                            <div className="desktop-ads desktop_ad_large">
                                <AdSlot slotId="test1" dfpNetworkId="205019744" sizes={[[970, 250]]} adUnit="MJUNOON.TV-970x250"  />
                            </div>
                        </div> : <div className="ad_container uk-margin-top">
                            <div className="desktop-ads11 mob_ad">
                                <AdSlot slotId="below-slider-ad" dfpNetworkId="205019744" sizes={[ [320, 100]]} adUnit="MJUNOON.TV-320X100" />
                            </div>
                        </div>} */}

                        {/* Home Body */}
                        {(this.state.homes.isLoaded === true && this.state.homes.list.length > 0 && this.state.banners.isLoaded === true) ? this.handleHomeList() : <HomeShimmer/>}
                        {/* Home Topic Base Data Body */}
                        {/* {(this.state.topicBase.isLoaded === true && this.state.topicBase.list.length > 0 )? this.handleTopicBaseData() : ''}             */}
                        {/* <InfiniteScroll style={{ overflow: 'none' }}
                                        dataLength={this.state.topicBase.list.length} //This is important field to render the next data
                                        next={this.topicBase}
                                        hasMore={this.state.hasMore}
                                        loader={<div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}
                                        endMessage={<UnifiedBanner/>}
                        >
                            {(this.state.topicBase.isLoaded === true && this.state.topicBase.list.length > 0 && this.state.banners.isLoaded === true) ? this.handleTopicBaseData() : ''}
                        </InfiniteScroll> */}

                        <div  className={`totop uk-position-fixed uk-position-bottom-right ${this.state.totopvisible ? "uk-visible" : "uk-hidden"}`} style={{zIndex:'20' , marginBottom:'20px'}} >
                            <a style={{height:'inherit' , color:'white'}} href='#' uk-totop ='' uk-scroll='' className='' ></a>
                        </div>

                        <UnifiedBanner/>
                    </div>
                    <Footer />
                    </> : <Loader/>
                    
                    // 
                }
                
            
                 
            </div> 
        );
    }
}
export default withRouter(Home);